import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalesService } from './globales.service';

@Injectable({
  providedIn: 'root',
})
export class PolicyService {
  constructor(private http: HttpClient, private globales: GlobalesService) {}

  addPolicy(obj) {
    return this.http.post(`${this.globales.dominioApi}/policy/addPolicy`, obj);
  }

  changeStatusPolicy(idPolicy: string, status: number) {
    const statusPolicy = status.toString();
    return this.http.get(
      `${this.globales.dominioApi}/policy/changeStatusPolicy`,
      {
        params: {
          idPolicy,
          statusPolicy,
        },
      }
    );
  }

  deletePolicy(idPolicy: string) {
    return this.http.get(`${this.globales.dominioApi}/policy/deletePolicy`, {
      params: {
        idPolicy,
      },
    });
  }

  getPolicy(idPolicy: string) {
    return this.http.get(`${this.globales.dominioApi}/policy/getPolicy`, {
      params: {
        idPolicy,
      },
    });
  }

  getPolicys(idEmpresa: string, disa = true) {
    let a = disa.toString();
    return this.http.get(`${this.globales.dominioApi}/policy/getPolicys`, {
      params: {
        idEmpresa,
        disabled: a,
      },
    });
  }
}
