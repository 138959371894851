import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalesService } from './globales.service';

@Injectable({
  providedIn: 'root',
})
export class UbicacionService {
  constructor(private http: HttpClient, private globales: GlobalesService) {}

  getPaises(): any {
    if (sessionStorage.getItem('getPaises')) {
      return sessionStorage.getItem('getPaises');
    } else {
      return this.http.get(`${this.globales.dominioApi}/ubicacion/getPaises`);
    }
  }

  getEstados(idPais: string): any {
    return this.http.get(
      `${this.globales.dominioApi}/ubicacion/getEstados/${idPais}`
    );
  }

  addPais(datos): any {
    return this.http.post(`${this.globales.dominioApi}/ubicacion/addPais/`,datos);
  }

}
