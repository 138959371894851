import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

const routes: Routes = [
  {
    path: 'callsinsight',loadChildren:()=>import('./components/pda/pda.module').then(m=>m.PdaModule)
  },
  {
    path: 'login',
    loadChildren:()=>import('./components/login/login.module').then(m=>m.LoginModule)
  },
  {
    path: 'main',
    loadChildren:()=>import('./components/main/main.module').then(m=>m.MainModule)
  },
  { path: 'fda/:idAppointment', loadChildren:()=>import('./components/temp/fda/customer/download/download.module').then(m=>m.downloadModule) },
  { path: 'board', loadChildren: ()=> import('./components/board/board.module').then(m=>m.boardModule) },
  { path: 'comingsoon', loadChildren: ()=> import('./components/shared/coming/coming.module').then(m=>m.comingModule) },
  { path: '**', pathMatch: 'full', redirectTo: 'login' },
]
  

@NgModule({
  imports: [BrowserModule, RouterModule.forRoot(routes,{ preloadingStrategy: PreloadAllModules ,  useHash: true, }),],
  // imports: [BrowserModule, RouterModule.forRoot(routes, {  relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class FeatureRoutingModule {}
