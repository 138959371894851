export class PersonaModel {
  idPersona: string;
  nombrePersona: string;
  apellidoPersona: string;
  correoPersona: string;
  altCorreoPersona: string;
  telefonoOficinaPersona: string;
  telefonoMovilPersona: string;
  generoPersona: boolean;
  direccionPersona: string;
  avatarPersona: string;
  fechaNacimientoPersona: Date;
  fechaRegistroPersona: Date;
}
