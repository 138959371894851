<section class="row main_logOp">
    <div class="col-12">
        <div class=" mt-0">
            <div class="">
                <div class="x-titulo-seccion   d-flex">
                    <div class="page_icono daportdata">
                        <i class="fal fa-file-alt"></i>
                    </div>
                    <span class="titulo">Task Log</span>
                </div>
               
                <hr>


                <div class="main-filter row justify-content-lg-end mb-3">

                    <div class="filter-text col-12 col-md-6 ">
                        <div class="form-group mb-0">
    
                            <div class="x-input">
                                <div class="icon">
                                    <i class="fal fa-search"></i>
                                </div>
                                <div class="flex-1">
                                    <input type="text" (keyup.enter)="getLogOp(false)" [(ngModel)]="criterio.texto" autocomplete="none" class="form-control" placeholder="Search..." />
                                </div>
                            </div>
                        </div>
                    </div>
    
                    <div class="col-md-3 col-12 justify-content-end align-self-start d-flex btn-filter">
                        <a role="button" (click)="viewFilter=!viewFilter" class="btn x-btn me-2"><i class="fal fa-filter me-2"></i><span> Filter</span></a>
                        <!-- <a role="button" (click)="viewFilter=!viewFilter" class="btn x-btn "><i class="fal fa-download me-2"></i><span> Export</span></a> -->
                    </div>
    
    
                    <div class="row d-flex justify-content-lg-end " *ngIf="viewFilter">
                        <div class="filter-fecha col-md-3 col-12 ">
                            <div class="form-group mb-0">
    
                                <div class="x-input p-relative">
                                    <div class="icon">
                                        <i class="fal fa-user"></i>
                                    </div>
                                    <div class="flex-1">
                                        <mat-date-range-input [max]="maxDate" (click)="picker.open()" [rangePicker]="picker">
                                            <input class="text-center" (dateChange)="getLogOp()" [(ngModel)]="criterio.fechaInicio" matStartDate #matStartDate placeholder="Start date">
                                            <input  class="text-center" (dateChange)="getLogOp()" [(ngModel)]="criterio.fechaFinal" matEndDate placeholder="End date">
                                        </mat-date-range-input>
                                        <div (click)="cleanPicker()" class="btnClearPicker pointer animate__animated animate__fadeIn" *ngIf="matStartDate.value">
        
                                            <i class="fal fa-times"></i>
                                        </div>  
                                        <mat-date-range-picker #picker></mat-date-range-picker>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="filter-ddl col-md-3 col-12 ">
        
                            <div class="form-group mb-0">
    
                                <div class="x-input flex-1">
         
                                    <p-multiSelect (onChange)="getLogOp()" [options]="opcionesMulti" placeholder="Select" [(ngModel)]="fMulti" optionLabel="name"></p-multiSelect>

                                </div>
    
                            </div>
        
                        </div>
                    </div>
                    
                </div>

                <div class="list_main">



                    <div *ngIf="cargando" class="d-flex justify-content-center my-5">
                        <i class="fa-2x fal fa-spin fa-spinner"></i>
                    </div>

                    <div *ngIf="!cargando" class="card_logOp">

                        <div *ngIf="listLog.length===0 " class="py-5 d-flex justify-content-center ">
                            <div class="text-center ">
                                <i class="fal fa-empty-set fa-2x "></i>
                                <span class="text-muted py-2 d-block ">Empty</span>
                            </div>
                        </div>

                        <div class="table-responsive " *ngIf="listLog.length>0">
                            <table class="table tableLogOp animate__animated animate__fadeIn ">
                                <thead>
                                    <tr>
                                        <th scope="col" class="min-w150px">Date</th>
                                        <th scope="col" class="min-w150px">Vessel</th>
                                        <th scope="col" class="min-w150px">Activity</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="pointer" (click)="ir(item)" *ngFor="let item of listLog">
                                        <td>
                                            <span>{{item.fecha |date:'MMM d, y h:mm a'}}</span>
                                        </td>

                                        <td>
                                            <span>{{item.vessel}}</span>
                                        </td>
                                        <td>
                                            <span class="d-block">{{item.actividad}}</span>
                                            <span class="">by: <i class="strong">{{item.nombreUsuario}}</i></span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            
                        </div>
                        <div *ngIf="listLog[listLog.length-1].total > listLog.length" class="d-flex justify-content-end px-2 my-3">
                            <a class="btn_ladda " (click)="cargarMas()">
                                <span class="text_ladda"><i class="fal fa-plus me-2"></i>Load More</span>
                            </a>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    </div>
</section>