import { Injectable } from '@angular/core';
import { GlobalesService } from './globales.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class BancoService {
  constructor(private globales: GlobalesService, private http: HttpClient) {}

  addBanco(obj) {
    return this.http.post(`${this.globales.dominioApi}/banco/addBanco`, obj);
  }

  changeStatusBanco(idBanco: string, status: number) {
    const statusBanco = status.toString();
    return this.http.get(
      `${this.globales.dominioApi}/banco/changeStatusBanco`,
      {
        params: {
          idBanco,
          statusBanco,
        },
      }
    );
  }

  deleteBanco(idBanco: string) {
    return this.http.get(`${this.globales.dominioApi}/banco/deleteBanco`, {
      params: {
        idBanco,
      },
    });
  }

  getCuentaBanco(idBanco: string) {
    return this.http.get(
      `${this.globales.dominioApi}/banco/getCuentaBancaria`,
      {
        params: {
          idBanco,
        },
      }
    );
  }

  getCuentasBancarias(idEmpresa: string, disa = true) {
    let a = disa.toString();
    return this.http.get(
      `${this.globales.dominioApi}/banco/getCuentasBancarias`,
      {
        params: {
          idEmpresa,
          disabled: a,
        },
      }
    );
  }

  getCurrencyBank() {
    return this.http.get(`${this.globales.dominioApi}/banco/getCurrencyBank`);
  }

  getInfoBank(idBanco){
    return new Promise( (resolve, reject)  => {

      this.getCuentaBanco(idBanco).subscribe(
        (datos: any) => {
          if(datos.idBanco){
            resolve(datos);
          }else{
            resolve(null);
          }
        });
    });
  }
}
