import { Pipe, PipeTransform } from '@angular/core';
import { GlobalesService } from '../services/globales.service';

@Pipe({ name: 'urlImg' })
export class urlImgPipe implements PipeTransform {
  transform(value: any, tipo: string): any {
    let url = '';
    const globales = new GlobalesService(null);
    switch (tipo) {
      case 'avatarLogos':
        url = globales.dominioContainer + globales.carpetaAvatarLogos + value;
        break;
        case 'fdaFiles':
        url = globales.dominioContainer + globales.carpetaFdaFile + value;
        break;
        case 'suplementaryFiles':
        url = globales.dominioContainer + globales.carpetaSuplementaryFile + value;
        break;

      default:
        break;
    }

    return url;
  }
}
