import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable({
    providedIn: 'root'
})
export class ComunityService {
    private menu = new BehaviorSubject('');
    private subMenu = new BehaviorSubject('');
    private icon = new BehaviorSubject('');
    private optMenu = new BehaviorSubject('');
    private portHub = new BehaviorSubject('');

    private historyUrl: string | null = null;

    menu$ = this.menu.asObservable();
    subMenu$ = this.subMenu.asObservable();
    icon$ = this.icon.asObservable();
    optMenu$=this.optMenu.asObservable();
    portHub$=this.portHub.asObservable();


    private dataOutlet = new BehaviorSubject('');

    dataOutlet$ = this.dataOutlet.asObservable();

    constructor(private location: Location, private router: Router) {
        this.router.events.subscribe((event) => {
          if (event instanceof NavigationEnd) {
            this.historyUrl = event.urlAfterRedirects;
          }
        });
      }
    
      goBack() {
        if (this.historyUrl) {
          this.location.back();
        } else {
          // Handle the case where there's no history (e.g., initial route)
          // You can stay on the current page or redirect to a specific route.
        }
      }
      
    changeDataOutlet(data: any) {
      this.dataOutlet.next(data)
    }

    viewPortHub(portHub:any){
        this.portHub.next(portHub);
    }

    changeData(menu: string, subMenu: string) {
        this.menu.next(menu);
        this.subMenu.next(subMenu);
    }

    changeIcon(icon:string){
        this.icon.next(icon);
    }

    changeOptMenu(optMenu:string){
        this.optMenu.next(optMenu);
    }


}