import { PersonaModel } from './persona.model';
import { EmpresaModel } from './empresa.model';
import { Menu } from './menu.model';
import { EmpresaComponent } from '../components/empresa/empresa.component';

export class UsuarioModel extends PersonaModel {
  idUsuario: string;
  nombreUsuario: string;
  passwordUsuario: string;
  statusUsuario: number;
  empresa: EmpresaModel;
  tokenUsuario: string;
  expiredTokenUsuario: number;
  administrador: boolean;
  departamentoUsuario: string;
  asignMenu: Menu[];
  menu: Menu[];
}
