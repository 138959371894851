import {
  CommonModule,
  LocationStrategy,
  HashLocationStrategy,
} from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule} from '@angular/common/http';
import { AppComponent } from './app.component';
import { FeatureRoutingModule } from './app.routes';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PdfMakeWrapper } from 'pdfmake-wrapper';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import {  MY_FORMATS } from './components/main/da-port/vesselpda/appointments-history/appointments-history.component';
import {  MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { BrowserModule } from '@angular/platform-browser';
import { SendSuplementaryComponent } from './components/shared/send-suplementary/send-suplementary.component';


PdfMakeWrapper.setFonts(pdfFonts);


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule, 
    CommonModule,
    FeatureRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,

    // MatStepperModule,
    // MatExpansionModule,
    // MatDialogModule,
    // MatDatepickerModule,
    // DragDropModule,
    // MatNativeDateModule,
    // DialogModule,
    // DropdownModule,
    // MultiSelectModule,
    // ChipsModule,
    // TimelineModule,
    // TableModule,
    // CalendarModule,
    // NgxMaskModule.forRoot(maskConfig),
    // NgChartsModule
  ],
  exports:[
  ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
    },
    {provide: DateAdapter, useClass:MomentDateAdapter , deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
