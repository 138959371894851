import { Pipe, PipeTransform } from '@angular/core';
import { CLIENT_RENEG_LIMIT } from 'tls';
import { GlobalesService } from '../services/globales.service';

@Pipe({ name: 'checkFixed' })
export class checkFixedPipe implements PipeTransform {

  global: GlobalesService = new GlobalesService(null);
  transform(value: any, cant: number): any {
    if (value !== 'No information') {
      return   this.global.toFixedNumber(Number(value),cant);
    } else {
      return 'To be advised';
    }
  }
}
