import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'vesselStatusTypeDate'
})
export class VesselStatusTypeDatePipe implements PipeTransform {

  transform(value: any,nombreItem:string, opt1: string, opt2: string): any {

   
    if (value) {
      if (value[nombreItem] === 'e') {

        if(value['operation'] && value['operation'] ==='anchor' ){

          return 'E-Anchor'
        }else{
          return opt1;
        }


      } else {
        if(value['operation'] && value['operation'] ==='anchor' ){

          return 'Anchor'
        }else{
          return opt2;
        }
      }
    } else {
      return opt1;
    }
    
  }

}
