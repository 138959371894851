import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'vesselStatusViewDate'
})
export class VesselStatusViewDatePipe implements PipeTransform {

  transform(value: any,opt1:string): any {

    if (opt1) {
      
        return opt1;
      
    }if(opt1===''){
      return null;

    } else {
      return value;
    }
    
  }

}
