import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { UsuarioModel } from '../models/usuario.model';
import { JoinModel } from '../models/empresa.model';
import { GlobalesService } from './globales.service';
import { FormControl, Validators, ValidationErrors } from '@angular/forms';
import { Observable } from 'rxjs';
import { Menu, SubMenu } from '../models/menu.model';

@Injectable({
  providedIn: 'root',
})
export class UsuarioService {
  usuario: UsuarioModel;
  tokenUsuario: string;

  constructor(private http: HttpClient, private globales: GlobalesService) {
    // this.getToken();
  }

  addCustomer(obj) {
    return this.http.post(
      `${this.globales.dominioApi}/usuario/addCustomer`,
      obj
    );
  }

  changeStatusCustomer(idCustomer: string, status: number) {
    return this.http.get(
      `${this.globales.dominioApi}/usuario/changeStatusCustomer`,
      {
        params: {
          idCustomer,
          status:status.toString(),
        },
      }
    );
  }

  deleteCustomer(idCustomer: string) {
    return this.http.get(`${this.globales.dominioApi}/usuario/deleteCustomer`, {
      params: {
        idCustomer,
      },
    });
  }

  getCustomersEmpresa(idEmpresa) {
    return this.http.get(
      `${this.globales.dominioApi}/usuario/getCustomersEmpresa`,
      {
        params: {
          idEmpresa,
        },
      }
    );
  }

  sendRestorePasswordUserCustomer(user) {
    return this.http.post(
      `${this.globales.dominioApi}/usuario/sendRestorePasswordUserCustomer`,
      user
    );
  }
  
  addEmployed(obj) {
    return this.http.post(
      `${this.globales.dominioApi}/usuario/addEmployed`,
      obj
    );
  }

  changeStatusEmployed(idUsuario: string, status: number) {
    const statusUsuario = status.toString();
    return this.http.get(
      `${this.globales.dominioApi}/usuario/changeStatusEmployed`,
      {
        params: {
          idUsuario,
          statusUsuario,
        },
      }
    );
  }

  deleteEmployed(idUsuario: string) {
    return this.http.get(`${this.globales.dominioApi}/usuario/deleteEmployed`, {
      params: {
        idUsuario,
      },
    });
  }

  getEmployed(idUsuario: string) {
    return this.http.get(`${this.globales.dominioApi}/usuario/getEmployed`, {
      params: {
        idUsuario,
      },
    });
  }

  getAcciones() {
    return this.http.get(`${this.globales.dominioApi}/usuario/getAcciones`);
  }

  checkNombreUsuario(nombreUsuario: string) {
    return this.http.get(
      `${this.globales.dominioApi}/usuario/checkNombreUsuario`,
      {
        params: {
          nombreUsuario,
        },
        observe: 'response',
      }
    );
  }

  checkNombreUsuarioCustomer(usuario: string) {
    return this.http.get(
      `${this.globales.dominioApi}/usuario/checkNombreUsuarioCustomer`,
      {
        params: {
          usuario,
        },
        observe: 'response',
      }
    );
  }

  checkCustomerCompany(customerCompany: string) {
    return this.http.get(
      `${this.globales.dominioApi}/usuario/checkCustomerCompany`,
      {
        params: {
          customerCompany,
        },
        observe: 'response',
      }
    );
  }

  validarCustomerCompany(control: FormControl): Promise<any> | Observable<any> | any {
    const promesa = new Promise((resolve, rejet) => {

      console.log(control.value);
      this.checkCustomerCompany(control.value).subscribe(
        (valido: any) => {
          if (valido.body.respuesta === 'true') {
            resolve({
              duplicado: true,
            });
          } else {
            resolve(null);
          }
        },
        (err) => {
          resolve({
            duplicado: true,
          });
        }
      );
    });
    return promesa;
  }

  validarNombreUsuario(control: FormControl): Promise<any> | Observable<any> {
    const promesa = new Promise((resolve, rejet) => {
      if (control.value !== this.usuario.nombreUsuario) {
        this.checkNombreUsuario(control.value).subscribe(
          (valido: any) => {
            if (valido.body.respuesta === 'true') {
              resolve({
                duplicado: true,
              });
            } else {
              resolve(null);
            }
          },
          (err) => {
            resolve({
              duplicado: true,
            });
          }
        );
      } else {
        resolve(null);
      }
    });
    return promesa;
  }

  passwordEditValidator(control: FormControl): { [s: string]: boolean } {
    if (control.value !== '********') {
      if (!control.value) {
        return {
          required: true,
        };
      } else {
        const a = new RegExp(this.globales.patternPassword);
        if (!a.test(control.value)) {
          return {
            pattern: true,
          };
        }
      }
      // return Validators.pattern(this.globales.patternPassword);
    } else {
      return null;
    }
  }

  altCorreoValidator = (control: FormControl): ValidationErrors => {
    if (control.value !== '') {
      control.setValidators(Validators.pattern(this.globales.patternEmail));
      control.updateValueAndValidity();
    }
    return null;
  };

  getUsuariosEmpresa(idEmpresa) {
    return this.http.get(
      `${this.globales.dominioApi}/usuario/getUsuariosEmpresa`,
      {
        params: {
          idEmpresa,
        },
      }
    );
  }

  sigin(usuario: UsuarioModel, passwordMd5: string) {
    // usuario.passwordUsuario = passwordMd5;
    let usu = {
      ...usuario,
      passwordUsuario: passwordMd5,
    };

    return this.http
      .post(`${this.globales.dominioApi}/usuario/singin`, usu)
      .pipe(
        map((resp: UsuarioModel) => {
          this.usuario = {
            ...resp,
          };
          localStorage.setItem('usuario', JSON.stringify(this.usuario));
          return this.usuario;
        })
      );
  }

  singinPDA(datos) {
    // usuario.passwordUsuario = passwordMd5;
    return this.http
      .post(`${this.globales.dominioApi}/usuario/singinPDA`, datos);

  }

  logout() {
    this.usuario = new UsuarioModel();
    localStorage.removeItem('usuario');
    sessionStorage.removeItem('getPaises');
    sessionStorage.removeItem('api');
    
  }

  join(join: JoinModel) {
    return this.http.post(`${this.globales.dominioApi}/usuario/join`, join);
  }

  resetPasswordUserCustomer(usuario: string) {
    let obj = {
      usuario,
    };
   
    console.log('a');
    return this.http.post(
      `${this.globales.dominioApi}/usuario/resetPasswordUserCustomer`,
      obj
    );
  }
  resetPassword(username: string) {
    let jsonBody = {
      username,
    };
   
    return this.http.post(
      `${this.globales.dominioApi}/usuario/resetpassword`,
      jsonBody
    );
  }

  newPassword(json: Object) {
    return this.http.post(
      `${this.globales.dominioApi}/usuario/newpassword`,
      json
    );
  }

  newPasswordUsuarioCustomer(obj: Object) {
    return this.http.post(
      `${this.globales.dominioApi}/usuario/newPasswordUsuarioCustomer`,
      obj
    );
  }

  // setToken(token: string) {
  //   this.tokenUsuario = token;
  //   localStorage.setItem('tokenUsuario', token);
  // }

  // getToken() {
  //   if (localStorage.getItem('tokenUsuario')) {
  //     this.tokenUsuario = localStorage.getItem('tokenUsuario')
  //   } else {
  //     this.tokenUsuario = '';
  //   }
  //   return this.tokenUsuario;
  // }

  isAuth() {
    if (this.usuario?.tokenUsuario) {
      return true;
    } else if (localStorage.getItem('usuario')) {
      this.usuario = JSON.parse(localStorage.getItem('usuario'));
      return true;
    } else {
      return false;
    }

    // let exp = new Date(this.usuario.expiredTokenUsuario);

    // if (exp > new Date()) {
    //   return true;
    // } else {
    //   return false;
    // }
  }
}
