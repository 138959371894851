import { Injectable } from '@angular/core';
import { GlobalesService } from './globales.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class PuertoService {
  constructor(private globales: GlobalesService, private http: HttpClient) {}

  addCuenta(obj) {
    return this.http.post(`${this.globales.dominioApi}/puerto/addCuenta`, obj);
  }
  addPlantillaEmpresa(idEmpresa: string, idPuerto: string) {
    return this.http.get(
      `${this.globales.dominioApi}/puerto/addPlantillaEmpresa`,
      {
        params: {
          idEmpresa,
          idPuerto,
        },
      }
    );
  }

  getZonas() {
    return this.http.get(`${this.globales.dominioApi}/puerto/getZonasPortuaria`);
  }

  getPuertos(idPais: string) {
    return this.http.get(`${this.globales.dominioApi}/puerto/getPuertosPais`, {
      params: {
        idPais,
      },
    });
  }
  getCuentas() {
    return this.http.get(`${this.globales.dominioApi}/puerto/getCuentas`);
  }

  getCuentasGrupoEmpresa(idEmpresa: string, codigoGrupo: string) {
    return this.http.get(
      `${this.globales.dominioApi}/puerto/getCuentasGrupoEmpresa`,
      {
        params: {
          idEmpresa,
          codigoGrupo,
        },
      }
    );
  }

  getActivityVessel() {
    return this.http.get(
      `${this.globales.dominioApi}/puerto/getActivityVessel`
    );
  }
  getCuentasEmpresa(idEmpresa: string, idPuerto: string) {
    return this.http.get(
      `${this.globales.dominioApi}/puerto/getCuentasEmpresa`,
      {
        params: {
          idEmpresa,
          idPuerto,
        },
      }
    );
  }

  uptOrderCuentasDAEngine(list) {
    return this.http.post(
      `${this.globales.dominioApi}/puerto/uptOrderCuentasDAEngine`,list);
  }
  

  deleteCuentaEmpresa(idCuentaEditada: string) {
    return this.http.get(
      `${this.globales.dominioApi}/puerto/deleteCuentaEmpresa`,
      {
        params: {
          idCuentaEditada,
        },
      }
    );
  }
}
